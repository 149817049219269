import {
  Button as ChakraButton,
  Box,
  Center,
  Hide,
  Spinner,
  Stack,
  Image,
  Show,
} from '@chakra-ui/react';

import IconBack from '@/lib/components/icons/IconBack';

import { Design } from '@/lib';
import { getDesign } from '@/api/designs';
import { useEffect, useState } from 'react';

import AppContainer from '@/layouts/AppContainer';
import { useHistory, useLocation } from 'react-router-dom';

import SubmitRemixForm from '../../components/remix/SubmitRemixForm';
import ButtonBack from '@/components/navbar/ButtonBack';

export default function SubmitRemix() {
  const [design, setDesign] = useState<Design>(null);

  const [isLoading, setLoading] = useState(true);

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const designId = searchParams.get('designId');

  const history = useHistory();

  useEffect(() => {
    getDesign(designId)
      .then((design) => setDesign(design))
      .finally(() => setLoading(false));
  }, []);

  const handleSubmitted = () => {
    history.push(`/share?designId=${design.id}`);
  };

  const { sides = [] } = design || {};

  const { previewImage } = sides[0] || {};

  return (
    <AppContainer
      contentContainerProps={{ p: 0 }}
      rightSideNavbarContent={
        <Show above="md">
          <ButtonBack onClick={() => history.push(`/designs/${design.id}`)} />
        </Show>
      }
    >
      {isLoading ? (
        <Center bg="transparent" h="100%">
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
        </Center>
      ) : (
        <Stack h="100%" direction={{ base: 'column', md: 'row' }}>
          <Box bg="#FFFFFF" h="100%" overflow="auto" pb={{ base: '60px', md: 0 }}>
            <Hide above="md">
              <Box p="12px 20px 12px 0">
                <ChakraButton onClick={() => history.push(`/designs/${design.id}`)} variant="ghost">
                  <IconBack />
                </ChakraButton>
              </Box>
            </Hide>
            <SubmitRemixForm design={design} onSubmitted={handleSubmitted} />
          </Box>
          <Show above="md">
            <Center flex={1}>
              <Image width={535} h={514} objectFit="contain" src={previewImage} />
            </Center>
          </Show>
        </Stack>
      )}
    </AppContainer>
  );
}
