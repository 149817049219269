import { useHistory, useLocation } from 'react-router-dom';
import GuestPageContainer from '@/layouts/GuestPageContainer';

import { useState } from 'react';

import Home from './Home';
import { Show } from '@chakra-ui/react';
import ButtonBack from '@/components/navbar/ButtonBack';

export default function HomeGuest() {
  const [isSignInModalVisible, setSignInModalVisible] = useState(false);

  const [votingForDesign, setVotingForDesign] = useState<string>(null);
  const [wantsToRemix, setWantsToRemix] = useState(false);

  const history = useHistory();

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const handleCancelSignIn = () => {
    setSignInModalVisible(false);
  };

  const handleSignedIn = () => {
    setSignInModalVisible(false);

    let destination = '/';

    if (wantsToRemix) {
      destination = `?remix=true`;
    } else if (votingForDesign) {
      destination = `?votingForDesign=${votingForDesign}`;
    }

    window.location.href = destination;
  };

  const handleSignInToVote = (designId: string) => {
    setVotingForDesign(designId);

    setSignInModalVisible(true);
  };

  const handleSignInToRemix = () => {
    setWantsToRemix(true);

    setSignInModalVisible(true);
  };

  return (
    <GuestPageContainer
      showSignInModal={isSignInModalVisible}
      onCancelSignIn={handleCancelSignIn}
      onSignedIn={handleSignedIn}
      rightSideNavbarContent={
        queryParams.get('collectionId') ? (
          <Show above="md">
            <ButtonBack onClick={() => history.replace('/')} />
          </Show>
        ) : null
      }
    >
      <Home onSignInToVote={handleSignInToVote} onSignInToRemix={handleSignInToRemix} />
    </GuestPageContainer>
  );
}
