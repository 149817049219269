import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Center, Spinner, Stack, useToast } from '@chakra-ui/react';

import { Elements } from '@stripe/react-stripe-js';
import { SetupIntent, loadStripe } from '@stripe/stripe-js';

import { Stripe } from 'stripe';

import {
  createSubscription,
  updateSubscription,
  usePlans,
  useSubscriptionStatus,
  usePaymentMethod,
  updatePaymentMethod,
  useCancelSubscription,
} from '@/api/subscription';
import Config from '@/config';

import ActivePlan from './cards/ActivePlanCard';
import PaymentMethod from './cards/payment-method';
import Invoices from './invoices';

import CustomStylePurchasedBanner from './components/banners/CustomStylePurchased';
import ConfirmDowngradeModal from './components/modals/ConfirmDowngradeModal';
import PlanUpdatedModal from './components/modals/PlanUpdatedModal';
import CustomInquirySentModal from './components/modals/CustomInquirySentModal';

import CheckoutForm from './payment/CheckoutForm';

import PlansModal from './plans';
import { FREE_PLAN_ID } from './plans/constants';
import { useQueryClient } from '@tanstack/react-query';
import { ApiKeysCard } from './cards/ApiKeysCard';
import AppContainer from '@/layouts/AppContainer';
import { useMe } from '../../../api/auth';

const { STRIPE_PUBLISHABLE_KEY } = Config;

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function Subscription() {
  const [isPlansModalVisible, setPlansModalVisible] = useState(false);

  const [isCustomInquirySentModalVisible, setCustomInquirySentModalVisible] = useState(false);
  const [isConfirmDowngradeModalVisible, setConfirmDowngradeModalVisible] = useState(false);
  const [isPlanUpdatedModalVisible, setIsPlanUpdatedModalVisible] = useState(null);

  const [isCustomStylePurchasedBannerVisible, setCustomStylePurchasedBannerVisible] =
    useState(false);

  const [selectedPlan, setSelectedPlan] = useState<Stripe.Plan>(null);

  const [paymentOptions, setPaymentOptions] = useState(null);

  const toast = useToast();

  const { data: plans = [], isLoading: isLoadingPlans } = usePlans();
  const { data: me } = useMe();
  const { data: subscription, error: subscriptionError, isLoading } = useSubscriptionStatus();
  const { data: paymentMethod } = usePaymentMethod();

  const { cancelSubscription } = useCancelSubscription();
  const client = useQueryClient();
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    if (
      (searchParams.get('payment_intent') || searchParams.get('setup_intent')) &&
      searchParams.get('redirect_status') === 'succeeded'
    ) {
      setIsPlanUpdatedModalVisible(true);
    } else if (searchParams.get('hasPurchasedCustomStyle')) {
      setCustomStylePurchasedBannerVisible(true);
    }
  }, [search, history]);

  const activePlan =
    (subscription &&
      !subscriptionError &&
      subscription.status === 'active' &&
      plans.find(({ id }) => id === subscription.items.data[0].plan.id)) ||
    plans.find(({ id }) => id === FREE_PLAN_ID);

  const showToast = (error) =>
    toast({
      title: 'Error happened',
      description: error.response?.data?.message || error.message,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleSelectedPlan = (plan: Stripe.Plan | null) => {
    setPlansModalVisible(null);

    if (!plan || (activePlan && plan.amount < activePlan.amount)) {
      setSelectedPlan(plan);

      setConfirmDowngradeModalVisible(true);

      return;
    }

    handleChangePlan(plan);
  };

  const handleConfirmDowngradePlan = () => {
    setConfirmDowngradeModalVisible(false);

    handleChangePlan(selectedPlan);

    setSelectedPlan(null);
  };

  const handleChangePlan = (plan) => {
    if (!plan) {
      cancelSubscription();

      return;
    }

    const hasExistingSubscription =
      (subscription && !subscriptionError && subscription.status === 'active') || paymentMethod;

    const method = hasExistingSubscription ? updateSubscription : createSubscription;

    method(plan.id)
      .then((subscription) => {
        const pendingSetupIntent = subscription.pending_setup_intent as unknown as SetupIntent;
        const clientSecret =
          ((subscription.latest_invoice as Stripe.Invoice).payment_intent as Stripe.PaymentIntent)
            ?.client_secret || pendingSetupIntent?.client_secret;

        const type = (subscription.latest_invoice as Stripe.Invoice).payment_intent
          ? 'payment'
          : 'setup';

        if (!pendingSetupIntent?.payment_method && clientSecret) {
          setPaymentOptions({ clientSecret, type });
        } else {
          client.invalidateQueries(['subscription']);
        }
      })
      .catch(showToast);
  };

  const handlePaymentMethodChange = () => {
    updatePaymentMethod().then(({ id: sessionId }) =>
      stripePromise.then((stripe) => stripe.redirectToCheckout({ sessionId })).catch(showToast)
    );
  };

  const handleCustomPlanInquirySent = () => {
    setPlansModalVisible(false);

    setCustomInquirySentModalVisible(true);
  };

  const { type, clientSecret } = paymentOptions || {};

  return (
    <AppContainer>
      {paymentOptions ? (
        <Elements
          stripe={stripePromise}
          options={{
            // passing the client secret obtained from the server
            clientSecret,
          }}
        >
          <CheckoutForm type={type} />
        </Elements>
      ) : (
        <Box>
          {isLoading || isLoadingPlans ? (
            <Center bg="transparent" h="100vh">
              <Spinner
                thickness="1px"
                speed="0.65s"
                emptyColor="gray"
                color="brand.500"
                size="md"
              />
            </Center>
          ) : (
            <Box>
              {isCustomStylePurchasedBannerVisible ? (
                <CustomStylePurchasedBanner
                  onClose={() => setCustomStylePurchasedBannerVisible(false)}
                />
              ) : null}
              <Stack
                direction={{ base: 'column', md: 'row' }}
                h={{ base: 'auto', md: '225px' }}
                mb="50px"
                spacing={{ base: '24px', md: '8px' }}
              >
                <ActivePlan onChange={() => setPlansModalVisible(true)} plan={activePlan} />
                {me.client.stripeCustomerId && (
                  <PaymentMethod
                    onChange={handlePaymentMethodChange}
                    paymentMethod={paymentMethod}
                  />
                )}
                <ApiKeysCard />
              </Stack>
            </Box>
          )}
          <Invoices />
        </Box>
      )}
      {isPlansModalVisible ? (
        <PlansModal
          onClose={() => setPlansModalVisible(null)}
          onSelectedPlan={handleSelectedPlan}
          onCustomPlanInquirySent={handleCustomPlanInquirySent}
          subscription={subscription}
        />
      ) : null}

      {isConfirmDowngradeModalVisible ? (
        <ConfirmDowngradeModal
          onClose={() => setConfirmDowngradeModalVisible(false)}
          onConfirm={handleConfirmDowngradePlan}
        />
      ) : null}
      {isPlanUpdatedModalVisible ? (
        <PlanUpdatedModal
          onClose={() => {
            // Invalidate here as webhook to update BE could be delayed
            client.invalidateQueries(['subscription']);
            setIsPlanUpdatedModalVisible(false);

            history.replace('/dashboard');
          }}
        />
      ) : null}
      {isCustomInquirySentModalVisible ? (
        <CustomInquirySentModal onClose={() => setCustomInquirySentModalVisible(false)} />
      ) : null}
    </AppContainer>
  );
}
