import { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import {
  Box,
  Center,
  HStack,
  Modal,
  ModalOverlay,
  Show,
  Spinner,
  useToast,
} from '@chakra-ui/react';

import { createDesign, getDesign, updateDesignSide } from '@/api/designs';

import Button from '@/lib/components/Button';
import IconArrowRight from '@/lib/components/icons/IconArrowRight';
import { Design, Favorite, FontToImageRequest, TextToImageRequest } from '@/lib/types';

import FeedbackAlert from './components/FeedbackAlert';

import { useMe } from '@/api/auth';
import EditorTool from '@/lib/editor';

import { generateImageFromImage } from '@/api/photo-transformer';
import { removeBackgroundByFile, removeBackgroundByUrl } from '@/api/background-removal';

import { useAddFavorite, useFavorites, useRemoveFavorite } from '@/api/favorites';

import OutOfCreditsModal from '@/components/subscription/OutOfCreditsModal';
import { BillableActionType } from '@/components/types';
import { useDebouncedValue } from '@/hooks/useDebouncedValue';
import { Hint, getCanvasHintProps } from '@/lib/editor/components/hints/hints';
import ArtboardSelector from '@/lib/editor/artboard-selector';

import HintPopover from '@/lib/editor/components/hints/HintPopover';
import { getTemplateWithInitialCanvasStates } from '@/lib/utils/template-preview';
import { generateImageFromFont } from '../../../../api/fontmaker';
import { getStyles } from '../../../../api/style';

import DesignPreview from '../design/DesignPreview';

import AppContainer from '@/layouts/AppContainer';

import { EDITOR_ERROR, ImageToImageRequest, ToolType } from '@/lib';
import { useBillableActions } from '@/api/subscription';
import LoadingOverlay from '@/components/modals/LoadingOverlay';
import { generateImageFromText } from '../../../../api/image-maker';

const { OPERATION_NOT_ALLOWED } = EDITOR_ERROR;

const ACTION_MAPPING = {
  [BillableActionType.IMAGE_MAKER]: ToolType.TEXT_TO_IMAGE,
  [BillableActionType.PHOTO_TRANSFORMER]: ToolType.IMAGE_TO_IMAGE,
  [BillableActionType.FONTMAKER]: ToolType.FONT_TO_IMAGE,
  [BillableActionType.BACKGROUND_REMOVAL]: ToolType.BACKGROUND_REMOVAL,
};

export default function EditorPage() {
  const [activeDesign, setActiveDesign] = useState<Design>(null);

  const [templateSideId, setTemplateSideId] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [isArtboardSelectorVisible, setArtboardSelectorVisible] = useState(false);

  const [isOutOfCreditsModalVisible, setIsOutOfCreditsModalVisible] = useState(false);
  const [isRemovingBackground, setRemovingBackground] = useState(false);

  const [isQuickSaving, setQuickSaving] = useState(false);

  const debouncedDesign = useDebouncedValue(activeDesign, 500);

  const { removeFavorite: removeFavoriteBase } = useRemoveFavorite();
  const { addFavorite: addFavoriteBase } = useAddFavorite();

  const { data: me } = useMe();
  const { data: favorites } = useFavorites(me.id);

  const toast = useToast();

  const { data: billableActions } = useBillableActions();

  const { id: designId } = useParams<{ id: string }>();

  const [activeHint, setActiveHint] = useState<Hint>(null);

  const [errorSavingDesign, setErrorSavingDesign] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (debouncedDesign && templateSideId && hasChanges) {
      handleQuickSave(debouncedDesign, templateSideId);
    }
  }, [debouncedDesign, templateSideId, history, hasChanges]);

  useEffect(() => {
    if (designId === 'new') {
      setIsLoading(false);

      setArtboardSelectorVisible(true);

      return;
    }

    getDesign(designId)
      .then((design) =>
        getTemplateWithInitialCanvasStates(design.template).then((template) => {
          setActiveDesign({ ...design, template });

          setIsLoading(false);
        })
      )
      .catch(() => setIsLoading(false));
  }, [designId]);

  const handleGoToSaveDesign = () => {
    const hasAtleastOneDesign = activeDesign.sides.find((side) => {
      const { canvasState } = side;

      return canvasState && JSON.parse(canvasState)?.objects?.length;
    });

    if (!hasAtleastOneDesign) {
      toast({
        description: 'Please add at least one design',
        position: 'top',
        status: 'error',
        duration: 2000,
      });

      return;
    }

    history.push(`/submit?designId=${activeDesign.id}`);
  };

  const handleGenerateImageFromText = (params: TextToImageRequest) =>
    checkIfEnoughCredits(BillableActionType.IMAGE_MAKER).then(() => generateImageFromText(params));

  const handleGenerateImageFromImage = (params: ImageToImageRequest) =>
    checkIfEnoughCredits(BillableActionType.PHOTO_TRANSFORMER).then(() =>
      generateImageFromImage(params)
    );

  const handleGenerateImageFromFont = (params: FontToImageRequest) =>
    checkIfEnoughCredits(BillableActionType.FONTMAKER).then(() => generateImageFromFont(params));

  const handleRemoveBackground = (imageUrl: string, isByUrl = false) =>
    checkIfEnoughCredits(BillableActionType.BACKGROUND_REMOVAL).then(() => {
      setRemovingBackground(true);

      const promise = isByUrl ? removeBackgroundByUrl : removeBackgroundByFile;

      return promise(imageUrl)
        .then((result) => {
          setRemovingBackground(false);

          return result;
        })
        .finally(() => setRemovingBackground(false));
    });

  const checkIfEnoughCredits = (action: BillableActionType) => {
    const requiredCredits = billableActions.find(({ name }) => action === name).credits;

    if (me.client.credits < requiredCredits) {
      setIsOutOfCreditsModalVisible(true);

      return Promise.reject(new Error(OPERATION_NOT_ALLOWED));
    }

    return Promise.resolve({});
  };

  const handleQuickSave = async (design, templateSideId) => {
    const { sides } = design;

    for (const side of sides) {
      const { canvasState, id, templateSide } = side;

      if (templateSideId !== templateSide?.id || !canvasState) {
        return;
      }

      setQuickSaving(true);

      try {
        await updateDesignSide(design.id, {
          canvasState,
          id,
        });
      } finally {
        setQuickSaving(false);
      }
    }
  };

  const handleDuplicate = () => {
    createDesign({ id: designId }).then((newDesign) => {
      history.push(`/designs/${newDesign.id}`);
    });
  };

  const handleCreatedDesign = (newDesign) => {
    history.push(`/designs/${newDesign.id}`);

    setArtboardSelectorVisible(false);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const removeFavorite = (id: string) =>
    removeFavoriteBase({
      id,
      userId: me.id,
    });

  const addFavorite = (favorite: Favorite) => addFavoriteBase({ ...favorite, userId: me.id });

  const isOwnDesign = !activeDesign || activeDesign.userId === me.id;

  const actionCosts = billableActions
    ? billableActions.reduce((result, action) => {
        const { name, credits } = action;

        const toolType = ACTION_MAPPING[name];

        if (toolType) {
          return { ...result, [toolType]: credits };
        }

        return result;
      }, {})
    : undefined;

  return (
    <AppContainer
      contentContainerProps={{ p: 0 }}
      rightSideNavbarContent={
        <Show above="lg">
          <HStack>
            <HintPopover
              isOpen={activeHint === Hint.FINISH_AND_SHARE}
              {...getCanvasHintProps(Hint.FINISH_AND_SHARE)}
              offset={[0, 20]}
              onNext={() => setActiveHint(null)}
              onClose={() => setActiveHint(null)}
            >
              <Center
                {...(activeHint === Hint.FINISH_AND_SHARE
                  ? {
                      bg: '#FFFFFF',
                      borderRadius: '12px',
                      h: '42px',
                      pl: '12px',
                      zIndex: 4,
                    }
                  : {})}
              >
                {isOwnDesign ? (
                  <Button
                    color="#000000"
                    iconRight={<IconArrowRight />}
                    onClick={handleGoToSaveDesign}
                    textTransform="none"
                    variant="secondary"
                    isDisabled={isQuickSaving}
                    title="Review"
                  />
                ) : null}
              </Center>
            </HintPopover>
          </HStack>
        </Show>
      }
    >
      {isLoading ? (
        <Center bg="#FFFFFF" h={{ base: 'calc(100% - 70px)', md: '100%' }}>
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" size="md" />
        </Center>
      ) : isOwnDesign ? (
        <EditorTool
          actionCosts={actionCosts}
          design={activeDesign}
          generateImageFromImage={handleGenerateImageFromImage}
          generateImageFromText={handleGenerateImageFromText}
          generateImageFromFont={handleGenerateImageFromFont}
          getStyles={getStyles}
          isQuickSaving={isQuickSaving}
          onDesignChange={(design, templateSideId, isInitialLoad = false) => {
            setTemplateSideId(templateSideId);

            setActiveDesign(design);

            setHasChanges(!isInitialLoad);
          }}
          onBack={handleGoBack}
          onNext={handleGoToSaveDesign}
          onActivateHint={setActiveHint}
          removeBackgroundByUrl={(imageUrl) => handleRemoveBackground(imageUrl, true)}
          removeBackgroundByFile={(imageFile) => handleRemoveBackground(imageFile as string)}
          addFavorite={addFavorite}
          favorites={favorites}
          removeFavorite={removeFavorite}
        />
      ) : (
        <DesignPreview onDuplicate={handleDuplicate} />
      )}

      {errorSavingDesign && (
        <Box
          position="absolute"
          left={{ base: 0, md: '393px' }}
          right={0}
          top={{ base: '191px', md: '140px' }}
        >
          <FeedbackAlert error={errorSavingDesign} onClose={() => setErrorSavingDesign(null)} />
        </Box>
      )}
      {activeHint ? (
        <Modal isOpen={activeHint !== null} onClose={() => {}}>
          <ModalOverlay zIndex={3} />
        </Modal>
      ) : null}
      {isArtboardSelectorVisible ? (
        <ArtboardSelector onCreatedDesign={handleCreatedDesign} />
      ) : null}
      {isOutOfCreditsModalVisible ? (
        <OutOfCreditsModal onClose={() => setIsOutOfCreditsModalVisible(false)} />
      ) : null}
      {isRemovingBackground ? <LoadingOverlay title="Removing..." /> : null}
    </AppContainer>
  );
}
