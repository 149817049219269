import { Template } from '@/components/types';
import { TemplateColorImage } from '@/lib';
import { Image, Box } from '@chakra-ui/react';

const IMAGE_MOBILE_SIZE = 394;
const IMAGE_DESKTOP_SIZE = 500;

const getTemplatePreviewImage = (template) => {
  const { colors, sides } = template;

  return (
    template.previewImage ||
    (colors[0].images as TemplateColorImage[]).find(
      ({ templateSideId }) => templateSideId === sides[0]?.id
    )?.url
  );
};

interface TemplatePreviewImageProps {
  template: Template;
}

const TemplatePreviewImage = ({ template }: TemplatePreviewImageProps) => (
  <Box
    bg="#ededed"
    display="flex"
    alignItems="center"
    justifyContent="center"
    h={{ base: IMAGE_MOBILE_SIZE, lg: IMAGE_DESKTOP_SIZE }} // No extra padding, only image size
    w={{ base: '100%', lg: IMAGE_DESKTOP_SIZE + 50 }}
    borderRadius={{ base: 0, '2sm': '24px', lg: '25px' }}
  >
    <Image
      objectFit="contain"
      src={getTemplatePreviewImage(template)}
      alt="Template image"
      h={{ base: IMAGE_MOBILE_SIZE, lg: IMAGE_DESKTOP_SIZE }}
      w={{ base: IMAGE_MOBILE_SIZE, lg: IMAGE_DESKTOP_SIZE }}
    />
  </Box>
);

export default TemplatePreviewImage;
