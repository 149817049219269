import axios from 'axios';

import { ImageToImageRequest } from '@/lib/types';

export const generateImageFromImage = (params: ImageToImageRequest) =>
  axios
    .post(`/photo-transformer/file`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      return data.images;
    });
