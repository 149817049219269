import {
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { useMe } from '@/api/auth';

import { StorageKeys } from '@/constants';

import { ellipsize } from '../../utils/string-utils';
import { IconCopy } from '../../../../lib/editor/object-edit-tools/Icons';
import { useState } from 'react';
import { ApiKeysModal } from '../components/modals/api-keys-modal/ApiKeysModal';

import ApiIntegationConfirmationModal from '../components/modals/api-keys-modal/ApiIntegrationConfirmationModal';

const { HAS_CONFIRMED_INTEGRATION } = StorageKeys;

export const ApiKeysCard = () => {
  const { data: me } = useMe();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const activeApiKey = me.client.apiKeys
    .filter((apiKey) => !apiKey.isDeleted)
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))[0];

  const handleCopyToClipboard = async () => {
    if (!localStorage.getItem(HAS_CONFIRMED_INTEGRATION)) {
      setConfirmationModalVisible(true);

      return;
    }

    copyToClipboard();
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(activeApiKey.key);
    toast({ title: 'API key copied to clipboard', status: 'success', position: 'top' });
  };

  const handleConfirmation = () => {
    localStorage.setItem(HAS_CONFIRMED_INTEGRATION, 'true');

    setConfirmationModalVisible(false);

    copyToClipboard();
  };

  const handleMoreClick = () => {
    setIsModalOpen(true);
  };

  return (
    <Card bg="#FFFFFF" borderRadius={20} height="100%" w={{ base: 'auto', md: '400px' }}>
      <CardBody p="17px 26px">
        <Flex align="center" justify="space-between" mb="20px">
          <Text color="black.700" fontSize="18px" fontWeight={700}>
            API keys
          </Text>
        </Flex>
        <VStack
          border="1px solid #D1D1D1"
          borderRadius="16px"
          justify="space-between"
          p="19px 18px"
        >
          <HStack w="100%">
            <Text color="black.200" fontSize="16px" fontWeight={700}>
              API key
            </Text>
            <Spacer />
            <Button borderRadius={10} onClick={handleMoreClick}>
              More
            </Button>
          </HStack>

          <HStack w="100%" cursor="pointer" onClick={handleCopyToClipboard}>
            <Text color="black.200" fontSize="16px" fontWeight={500}>
              {ellipsize(activeApiKey.key, 25)}
            </Text>
            <Spacer />
            <IconCopy />
          </HStack>

          <Text
            color="secondaryDarkGray.600"
            fontSize="13"
            fontWeight={500}
            alignSelf="flex-start"
          >{`Created on ${
            activeApiKey.createdAt.toString().replace('T', ' ').split('.')[0]
          }`}</Text>
        </VStack>
      </CardBody>
      {isModalOpen && (
        <ApiKeysModal
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      {isConfirmationModalVisible ? (
        <ApiIntegationConfirmationModal
          onConfirm={handleConfirmation}
          onClose={() => setConfirmationModalVisible(false)}
        />
      ) : null}
    </Card>
  );
};
