import { Center, Image, VStack } from '@chakra-ui/react';

import FeatureCardEmptyPlaceholder from './images/FeatureCardEmptyPlaceholder.png';
import Button from '@/components/button';

interface FeatureCardEmptyProps {
  onRemix: () => void;
  waiting: boolean;
}

const FeatureCardEmpty = ({ onRemix, waiting }: FeatureCardEmptyProps) => (
  <Center border="1px solid #CBD5E0" borderRadius="12px" h="533px" w="382px">
    <VStack spacing="38px">
      <Image h="240px" src={FeatureCardEmptyPlaceholder} />
      <Button h="50px" isLoading={waiting} onClick={onRemix} w="224px">
        Start designing
      </Button>
    </VStack>
  </Center>
);

export default FeatureCardEmpty;
