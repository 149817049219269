import { Box } from '@chakra-ui/react';

import Button from '@/components/button';

import { useDebouncedValue } from '@/hooks/useDebouncedValue';
import { Design, Favorite } from '@/lib/types';
import { Template } from '@/components/types';
import EditorTool from '@/lib/editor';

import { generateImageFromImage } from '@/api/photo-transformer';

import { useAddFavorite, useFavorites, useRemoveFavorite } from '@/api/favorites';
import { useEffect, useState } from 'react';
import { updateTemplateSide } from '@/api/templates';
import { useMe } from '@/api/auth';
import { removeBackgroundByUrl } from '../../../../../api/background-removal';
import { generateImageFromFont } from '../../../../../api/fontmaker';
import { getStyles } from '../../../../../api/style';
import { generateImageFromText } from '../../../../../api/image-maker';

const getColorsForDesign = (colors) =>
  colors.map((color, index) => ({
    ...color,
    images: Object.keys(color.images).map((key) => {
      const colorImage = color.images[key];

      if (colorImage.id && colorImage.url) {
        return colorImage;
      }

      return {
        dataUrl: colorImage,
        templateSideId: key,
      };
    }),
    id: color.id || `${index}`,
  }));

type DesignElementsProps = {
  onNext: (updates: object) => void;
  onUpdate: (templateSideId: string, initialCanvasState: string) => void;
  template: Template;
};

const DesignElements = ({ onNext, onUpdate, template }: DesignElementsProps) => {
  const [templateSideId, setTemplateSideId] = useState('');
  const [hasChanges, setHasChanges] = useState(false);

  const [design, setDesign] = useState<Design>(null);

  const debouncedDesign = useDebouncedValue(design, 500);

  const { data: me } = useMe();

  const { removeFavorite: removeFavoriteBase } = useRemoveFavorite();
  const { addFavorite: addFavoriteBase } = useAddFavorite();

  const { data: favorites } = useFavorites(me.id);

  useEffect(() => {
    if (debouncedDesign && debouncedDesign.template?.id && templateSideId && hasChanges) {
      handleQuickSave(debouncedDesign, templateSideId);
    }
  }, [debouncedDesign, templateSideId, hasChanges]);

  useEffect(() => {
    const { colors: templateColors, sides } = template;

    const colors = getColorsForDesign(templateColors);

    const defaultColor = colors[0];

    const design = {
      templateColorId: defaultColor.id,
      template: {
        ...template,
        colors,
      },
      templateId: null,
      sizeId: null,
      sides: sides.map((side) => ({
        templateSideId: side.id,
        templateSide: side,
      })),
    };

    setDesign(design);
  }, []);

  const handleQuickSave = (design, templateSideId) => {
    const { sides, template } = design;

    sides.forEach((side) => {
      const { canvasState, templateSide } = side;

      if (templateSideId !== templateSide?.id || !canvasState) {
        return;
      }

      updateTemplateSide(template.id, {
        id: templateSideId,
        initialCanvasState: canvasState,
      });
    });
  };

  const removeFavorite = (id: string) =>
    removeFavoriteBase({
      id,
      userId: me.id,
    });

  const addFavorite = (favorite: Favorite) => addFavoriteBase({ ...favorite, userId: me.id });

  if (!design) {
    return null;
  }

  return (
    <Box h="600px" pb="40px" position="relative" w="1100px" zIndex={1}>
      <EditorTool
        containerProps={{
          borderRadius: '20px',
          overflow: 'hidden',
        }}
        design={design}
        generateImageFromImage={generateImageFromImage}
        generateImageFromText={generateImageFromText}
        generateImageFromFont={generateImageFromFont}
        getStyles={getStyles}
        onDesignChange={(design, templateSideId, isInitialLoad = false) => {
          setTemplateSideId(templateSideId);

          setHasChanges(!isInitialLoad);

          const { canvasState } = design.sides.find(
            ({ templateSide }) => templateSide.id === templateSideId
          );

          setDesign(design);

          if (canvasState) {
            onUpdate(templateSideId, canvasState);
          }
        }}
        removeBackgroundByUrl={removeBackgroundByUrl}
        addFavorite={addFavorite}
        favorites={favorites}
        removeFavorite={removeFavorite}
      />
      <Button
        mt="20px"
        onClick={onNext}
        position="absolute"
        bottom="120px"
        left="14px"
        w="368px"
        zIndex={2}
      >
        Next
      </Button>
    </Box>
  );
};

export default DesignElements;
