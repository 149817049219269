import { useEffect, useState } from 'react';

import { HStack, VStack, Text, useToast } from '@chakra-ui/react';

import { capitalize } from 'lodash';

import { useCurrencies } from '@/api/currencies';

import FormInputBase from '@/components/form/FormInput';
import { updateTemplate } from '@/api/templates';

import Dropdown from '@/components/form/Dropdown';
import FormContainer from '../components/FormContainer';
import { Template } from '@/components/types';
import ImageUploadButton from '../components/ImageUploadButton';
import FormLabel from '@/components/form/FormLabel';
import { ARTBOARD_CATEGORY_OPTIONS } from '@/constants/artboards';

const FIELD_INROW_SPACING = '38px';
const INPUT_WIDTH = '307px';

const FIT_OPTIONS = ['Regular', 'Tight', 'Loose', 'Oversized'];

const GENDER_OPTIONS = ['male', 'female', 'unisex'];

const DEFAULT_VALUES = {
  brand: '',
  currencyId: 1,
  description: '',
  fit: 'Regular',
  gender: 'unisex',
  madeIn: '',
  fabric: '',
};

const FormInput = (props) => <FormInputBase w={INPUT_WIDTH} {...props} />;

type GeneralInformationProps = {
  onNext: (updates: object) => void;
  onUpdate: (updates: object) => void;
  templateInfo: Partial<Template>;
};

const GeneralInformation = ({ onNext, onUpdate, templateInfo }: GeneralInformationProps) => {
  const [values, setValues] = useState<Partial<Template>>(DEFAULT_VALUES);
  const [waiting, setWaiting] = useState(false);
  const [newArtboardIcon, setNewArtboardIcon] = useState<string>();

  useEffect(() => {
    setValues({
      ...DEFAULT_VALUES,
      ...templateInfo,
    });
  }, [templateInfo]);

  const { data: currencies = [] } = useCurrencies();

  const toast = useToast();

  const {
    brand,
    currencyId,
    fabric,
    gender,
    madeIn,
    name,
    fit,
    price,
    collectionDescription,
    description,
    manufacturingProductId,
    artboardCategory,
    artboardSizeDescription,
    artboardIconUrl,
  } = values;

  const hasAllFieldsFilled = name;

  const { id } = templateInfo;

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  const handleNext = () => {
    if (!id) {
      onNext(values);

      return;
    }

    setWaiting(true);

    updateTemplate(values)
      .then((data) => {
        toast({
          title: 'Changes saved',
          position: 'top',
          status: 'success',
        });
        onUpdate(data);
      })
      .finally(() => setWaiting(false));
  };

  return (
    <FormContainer
      onNext={handleNext}
      nextDisabled={!hasAllFieldsFilled}
      nextLabel={id && 'Save'}
      title="Template Information"
      waiting={waiting}
    >
      <VStack align="flex-start" spacing="20px" w="100%">
        <HStack spacing={FIELD_INROW_SPACING} w="100%">
          <VStack align="flex-start" spacing="20px" w="100%">
            <HStack spacing={FIELD_INROW_SPACING}>
              <FormInput
                name="Template Name"
                placeholder="Ex) Regular T-Shirt"
                value={name}
                onChange={(e) => handleUpdate({ name: e.target.value })}
              />
              <FormInput
                name="Fabric"
                isOptional
                placeholder="Ex) Recycled cotton jersey"
                value={fabric}
                onChange={(e) => handleUpdate({ fabric: e.target.value })}
              />
            </HStack>
            <HStack spacing={FIELD_INROW_SPACING}>
              <HStack align="flex-end">
                <Dropdown
                  isOptional
                  name="Price"
                  options={currencies}
                  selectedValue={currencyId}
                  onSelectedValue={(currencyId) => handleUpdate({ currencyId })}
                  width="auto"
                />
                <FormInputBase
                  placeholder="Ex) 34"
                  value={price}
                  onChange={(e) => handleUpdate({ price: parseFloat(e.target.value) })}
                  type="number"
                  w="230px"
                />
              </HStack>
              <FormInput
                isOptional
                name="Made"
                placeholder="Ex) Made In Portugal"
                value={madeIn}
                onChange={(e) => handleUpdate({ madeIn: e.target.value })}
              />
            </HStack>
          </VStack>
        </HStack>
        <HStack spacing={FIELD_INROW_SPACING}>
          <Dropdown
            name="Fit"
            isOptional
            options={FIT_OPTIONS.map((option) => ({ name: option, id: option }))}
            selectedValue={fit}
            onSelectedValue={(fit) => handleUpdate({ fit })}
            width={INPUT_WIDTH}
          />
          <Dropdown
            isOptional
            name="Gender"
            options={GENDER_OPTIONS.map((option) => ({ name: capitalize(option), id: option }))}
            selectedValue={gender}
            onSelectedValue={(gender) => handleUpdate({ gender })}
            width={INPUT_WIDTH}
          />
          <FormInput
            isOptional
            name="Brand"
            placeholder=""
            value={brand}
            onChange={(e) => handleUpdate({ brand: e.target.value })}
          />
        </HStack>
        <FormInput
          isOptional
          isTextArea
          name="Product Description"
          placeholder="Ex) Fabric made from 50% reclaimed cotton and 50% organic cotton. Jersey for that oh so soft feel. Treated with PRRMINT Oil. A durable odor control and broad-spectrum antimicrobial treatment that enables our products to stay fresh for longer."
          value={description}
          onChange={(e) => handleUpdate({ description: e.target.value })}
          size="lg"
          w="100%"
        />
        <FormInput
          isOptional
          isTextArea
          name="Collection Description"
          placeholder="Ex) A description for the associated collection"
          value={collectionDescription}
          onChange={(e) => handleUpdate({ collectionDescription: e.target.value })}
          size="lg"
          w="100%"
        />
        <FormInputBase
          isOptional
          name="Manufacturing Id"
          placeholder="Printful Product ID"
          value={manufacturingProductId}
          onChange={(e) => handleUpdate({ manufacturingProductId: e.target.value })}
          w="200px"
        />
        <Text color="black.600" fontSize="18px" mb="3">
          Artboards
        </Text>
        <HStack>
          <Dropdown
            isOptional
            name="Artboard Category"
            options={ARTBOARD_CATEGORY_OPTIONS.map((option) => ({
              name: capitalize(option),
              id: option,
            }))}
            selectedValue={artboardCategory || ''}
            onSelectedValue={(artboardCategory) => handleUpdate({ artboardCategory })}
            width={INPUT_WIDTH}
          />
          <FormInput
            name="Artboard Size Description"
            isOptional
            min={0}
            value={artboardSizeDescription || ''}
            onChange={(e) => handleUpdate({ artboardSizeDescription: e.target.value })}
          />
        </HStack>
        <VStack>
          <FormLabel isOptional>Preview Image</FormLabel>
          <FormLabel isOptional>(Click to update)</FormLabel>
          <ImageUploadButton
            image={newArtboardIcon || artboardIconUrl}
            onImageUploaded={(image) => {
              handleUpdate({ artboardIconBase64: image });
              setNewArtboardIcon(image);
            }}
            title="Artboard Icon"
          />
        </VStack>
      </VStack>
    </FormContainer>
  );
};

export default GeneralInformation;
