import axios from 'axios';

export const urlToUpscale = (imageUrl: string, scale: number): Promise<string> =>
  axios
    .post('/upscale', {
      imageUrl,
      scale,
    })
    .then(({ data }) => {
      return data.image;
    });

export const imageToUpscale = (imageFile: File, scale: number): Promise<string> =>
  axios
    .post(
      '/upscale/file',
      {
        image: imageFile,
        scale,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(({ data }) => {
      return data.image;
    });
