import { HStack, Image, useBreakpointValue } from '@chakra-ui/react';

import Ablo from './graphics-svgs/Ablo.svg';
import PolaroidBlack from './graphics-svgs/PolaroidBlack.svg';
import Box from './graphics-svgs/Box.svg';
import Pixel from './graphics-svgs/Pixel.svg';
import Cool from './graphics-svgs/Cool.svg';
import Fire from './graphics-svgs/Fire.svg';
import Hands from './graphics-svgs/Hands.svg';
import MyPhotos from './graphics-svgs/MyPhotos.svg';
import PolaroidWhite from './graphics-svgs/PolaroidWhite.svg';
import QRCodeContainer from './graphics-svgs/QRCodeContainer.svg';
import { GraphicsButton } from './GraphicsButton';

const GRAPHICS_MOBILE = [Ablo, PolaroidBlack, Fire, QRCodeContainer, Box, Pixel, Cool, Hands];

const GRAPHICS_ALL = [...GRAPHICS_MOBILE, MyPhotos, PolaroidWhite];

type Props = {
  onSelectedGraphic: (value: string) => void;
};

const GraphicsPicker = ({ onSelectedGraphic }: Props) => {
  const handleSelectedGraphic = (icon) => {
    fetch(icon)
      .then((response) => response.text())
      .then((iconAsSvgString) => onSelectedGraphic(iconAsSvgString));
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const graphics = isMobile ? GRAPHICS_MOBILE : GRAPHICS_ALL;

  return (
    <HStack spacing="4px">
      {graphics.map((icon, index) => {
        return (
          <GraphicsButton
            key={index}
            onClick={() => {
              if (index === 0) {
                onSelectedGraphic(null);

                return;
              }

              handleSelectedGraphic(icon);
            }}
          >
            <Image src={icon} />
          </GraphicsButton>
        );
      })}
    </HStack>
  );
};

export default GraphicsPicker;
