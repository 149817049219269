import { Box, HStack, Text, VStack } from '@chakra-ui/react';

import { Style } from '../../../../types';
import ImageUpload from '../../../../components/upload/ImageUpload';
import { StyleIcon } from './StyleIcon';

type StyleSelectorProps = {
  styles: Style[];
  selectedStyleId: string;
  onSelectedStyle: (styleId: string) => void;
  referenceImageFile: File;
  onReferenceImageFileChange?: (file: File) => void;
};

const UPLOAD_STYLE_NAME = 'upload style';

export default function StyleSelector({
  styles,
  selectedStyleId,
  onSelectedStyle,
  referenceImageFile,
  onReferenceImageFileChange,
}: StyleSelectorProps) {
  const handleReferenceImageFileSelected = (file: File, styleId: string): void => {
    onReferenceImageFileChange(file);
    onSelectedStyle(styleId);
  };

  const handleClick = (styleId: string, isInstaStyle: boolean) => {
    if (!isInstaStyle) {
      onReferenceImageFileChange(null);
    }

    onSelectedStyle(styleId);
  };

  // Move upload style to the end of the list
  const uploadStyleIndex = styles.findIndex((style) =>
    style.name.toLowerCase().includes(UPLOAD_STYLE_NAME)
  );
  if (uploadStyleIndex !== -1) {
    styles.push(styles.splice(uploadStyleIndex, 1)[0]);
  }

  return (
    <VStack align="left">
      <Text fontWeight={500} color="#000000" fontSize="18px">
        Select Style
      </Text>
      <HStack
        align="start"
        mt="12px"
        spacing="12px"
        w="100%"
        overflowY="auto"
        overflowX={{ base: 'auto', '3sm': 'hidden' }}
        wrap={{ base: 'nowrap', '3sm': 'wrap' }}
      >
        {styles.map(({ id, name, imageUrl }, index) => {
          const isSelected = id === selectedStyleId;
          const isUploadStyle = name.toLowerCase().includes('upload style');

          return (
            <Box
              key={index}
              onClick={() => handleClick(id, isUploadStyle)}
              borderRadius="4px"
              flexShrink="0"
              paddingBottom={4}
            >
              {isUploadStyle ? (
                <ImageUpload
                  customButtonContent={
                    referenceImageFile && (
                      <StyleIcon
                        isSelected={isSelected}
                        src={URL.createObjectURL(referenceImageFile)}
                        name={name}
                      />
                    )
                  }
                  label={name}
                  onFilesSelected={(files) => handleReferenceImageFileSelected(files[0], id)}
                />
              ) : (
                <StyleIcon isSelected={isSelected} src={imageUrl} name={name} />
              )}
            </Box>
          );
        })}
      </HStack>
    </VStack>
  );
}
