import { ReactNode, useState } from 'react';
import { Box, ButtonProps, Flex } from '@chakra-ui/react';

import {
  Style,
  StyleType,
  ImageToImageRequest,
  TextToImageRequest,
  FontToImageRequest,
  AbloImage,
  TextToImageResponse,
  Template,
} from '@/lib/types';

import EditorToolbar from './toolbar';

import TextToImageGenerator from './toolbar/text-to-image';
import ImageToImageGenerator from './toolbar/image-to-image';
import FontToImageGenerator from './toolbar/font-to-image';

import ToolType from './toolbar/ToolTypes';
import { Favorite } from '@/lib';
import FavoriteGallery from './toolbar/components/FavoriteGallery';

type EditorContainerProps = {
  actionCosts?: {
    [key in ToolType]?: number;
  };
  onImagesPreview?: (images: AbloImage[]) => void;
  onGeneratedImageSelected: (image: AbloImage) => void;
  isEditorToolbarExpanded: boolean;
  isEditing: boolean;
  onChangeEditorToolbarExpanded: (isExpanded: boolean) => void;
  children: React.ReactNode;
  getStyles: (type: StyleType) => Promise<Style[]>;
  generateImageFromText: (options: TextToImageRequest) => Promise<TextToImageResponse>;
  generateImageFromImage?: (options: ImageToImageRequest) => Promise<AbloImage[]>;
  generateImageFromFont?: (options: FontToImageRequest) => Promise<AbloImage[]>;
  hideStyles?: boolean;
  customToolbarContent?: ReactNode;
  buttonProps?: {
    basic: ButtonProps;
    outlined: ButtonProps;
  };
  availableTools?: ToolType[];
  subjectText?: string;
  addFavorite: (favorite: Favorite) => void;
  favorites: Favorite[];
  removeFavorite: (id: string) => void;
  template: Template;
};

export default function EditorContainer({
  actionCosts = {},
  onImagesPreview,
  onGeneratedImageSelected,
  isEditing,
  isEditorToolbarExpanded,
  onChangeEditorToolbarExpanded,
  children,
  getStyles,
  generateImageFromText,
  generateImageFromImage,
  generateImageFromFont,
  hideStyles,
  customToolbarContent,
  availableTools,
  buttonProps,
  subjectText,
  addFavorite,
  favorites,
  removeFavorite,
  template,
}: EditorContainerProps) {
  const [selectedTool, setSelectedTool] = useState(
    availableTools ? availableTools[0] : ToolType.TEXT_TO_IMAGE
  );

  const isImageToImage = selectedTool === ToolType.IMAGE_TO_IMAGE;

  return (
    <Flex
      align="center"
      bg="#F9F9F7"
      flexDirection={{ base: 'column', lg: 'row' }}
      paddingBottom={{ base: isEditing ? 0 : '180px', lg: 0 }}
      overflowY="auto"
      h={`calc(100% - ${template?.sides?.length > 1 ? 63 : 0}px)`}
      w="100%"
    >
      <EditorToolbar
        isExpanded={isEditorToolbarExpanded}
        onSetExpanded={onChangeEditorToolbarExpanded}
        onGeneratedImageSelected={onGeneratedImageSelected}
        selectedTool={selectedTool}
        onSelectedTool={setSelectedTool}
        hideButtons={
          !!customToolbarContent || (isEditorToolbarExpanded && availableTools?.length === 1)
        }
        availableTools={availableTools}
      >
        {customToolbarContent}
        <Box display={customToolbarContent ? 'none' : 'block'}>
          <Box display={selectedTool === ToolType.TEXT_TO_IMAGE ? 'block' : 'none'}>
            <TextToImageGenerator
              actionCost={actionCosts[ToolType.TEXT_TO_IMAGE]}
              getStyles={getStyles}
              generateImageFromText={generateImageFromText}
              hideStyles={hideStyles}
              onGeneratedImageSelected={onGeneratedImageSelected}
              onImagesPreview={onImagesPreview}
              buttonProps={buttonProps}
              subjectText={subjectText}
              addFavorite={addFavorite}
              favorites={favorites}
              removeFavorite={removeFavorite}
            />
          </Box>
          <Box display={isImageToImage ? 'block' : 'none'}>
            <ImageToImageGenerator
              actionCost={actionCosts[ToolType.IMAGE_TO_IMAGE]}
              getStyles={getStyles}
              generateImageFromImage={generateImageFromImage}
              onGeneratedImageSelected={onGeneratedImageSelected}
              addFavorite={addFavorite}
              favorites={favorites}
              removeFavorite={removeFavorite}
            />
          </Box>
          <Box display={selectedTool === ToolType.FONT_TO_IMAGE ? 'block' : 'none'}>
            <FontToImageGenerator
              actionCost={actionCosts[ToolType.FONT_TO_IMAGE]}
              getStyles={getStyles}
              generateImageFromFont={generateImageFromFont}
              onGeneratedImageSelected={onGeneratedImageSelected}
              addFavorite={addFavorite}
              favorites={favorites}
              removeFavorite={removeFavorite}
            />
          </Box>
          <Box padding="18px 20px" display={selectedTool === ToolType.FAVORITES ? 'block' : 'none'}>
            <FavoriteGallery
              favorites={favorites}
              removeFavorite={removeFavorite}
              onGeneratedImageSelected={onGeneratedImageSelected}
            />
          </Box>
        </Box>
      </EditorToolbar>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        h={{ base: 'auto', lg: '100%' }}
        w="100%"
        position={{ base: 'static', lg: 'relative' }}
      >
        {children}
      </Box>
    </Flex>
  );
}
