import { getCountdownLabel } from '@/utils/time';
import { Box, Text } from '@chakra-ui/react';

interface CountdownProps {
  secondsLeft: number;
}

const Countdown = ({ secondsLeft }: CountdownProps) => (
  <Box textAlign={{ base: 'center', lg: 'left' }}>
    <Text
      color={{ base: '#718096', lg: '#00000' }}
      fontSize={{ base: '12px', lg: '16px' }}
      fontWeight={500}
    >
      Winners will be chosen in:
    </Text>
    <Text fontSize="18px" fontWeight={500}>
      {getCountdownLabel(secondsLeft)}
    </Text>
  </Box>
);

export default Countdown;
