import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Hide,
  Image,
  Spinner,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';

import RemixesList from '@/components/remix/RemixesList';

import IconBack from '@/lib/components/icons/IconBack';
import { useHistory, useParams } from 'react-router-dom';
import { useTemplate } from '../../api/templates';
import { Design } from '../../lib';
import { useState } from 'react';
import RemixDetails from '../home/RemixDetails';
import { saveUserDesign, voteForDesign } from '../../api/designs';
import { isEmpty } from 'lodash';
import RemixDetailsModal from '../home/RemixDetailsModal';
import { Template } from '../../components/types';

const IMAGE_MOBILE_SIZE = 395;
const IMAGE_DESKTOP_SIZE = 400;

const getStats = (template: Template) => [
  {
    name: 'Total submissions',
    value: template.numRemixes,
  },
  {
    name: 'Total votes',
    value: template.numVotes,
  },
];

interface CollectionPageProps {
  onBack?: () => void;
  votingForDesign?: string;
  votingEnded?: boolean;
  votedForDesign?: string;
  onSignInToVote?: (designId: string) => void;
  onSignInToRemix?: () => void;
}

export default function CollectionPage({
  onBack,
  onSignInToVote,
  onSignInToRemix,
  votingEnded,
}: CollectionPageProps) {
  const { id } = useParams<{ id: string }>();
  const { data: template, isLoading, isError } = useTemplate(id);
  const [design, setDesign] = useState<Design | null>(null);
  const [showRemixDetails, setShowRemixDetails] = useState(false);
  const [isWaiting, setWaiting] = useState(false);
  const [votingForDesign, setVotingForDesign] = useState<string>(null);
  const [designVotes, setDesignVotes] = useState({});
  const [votedForDesign, setVotedForDesign] = useState<string>(null);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const history = useHistory();
  const toast = useToast();

  const { collectionDescription = '', name, previewImages = [] } = template || {};

  const handleDesignSelected = (design: Design) => {
    setDesign(design);
    setShowRemixDetails(true);
  };

  const handleRemix = async () => {
    if (onSignInToRemix) {
      onSignInToRemix();

      return;
    }

    const { colors, id } = template;

    const sides = template.sides.map((side) => ({
      templateSideId: side.id,
    }));

    const params = {
      sides,
      template,
      templateId: id,
      templateColorId: colors[0].id,
    };

    setWaiting(true);

    try {
      const newDesign = await saveUserDesign(params as Design);

      history.push(`/designs/${newDesign.id}`);
    } catch (e) {
      toast({
        title: 'Error creating a remix',
        status: 'error',
        duration: 2000,
      });
    } finally {
      setWaiting(false);
    }
  };

  const handleVote = (designToVoteForId: string) => {
    if (onSignInToVote) {
      onSignInToVote(designToVoteForId);

      return;
    }

    setVotingForDesign(designToVoteForId);

    voteForDesign(designToVoteForId)
      .then((newDesignVote) => {
        const votesForDesign = designVotes[designToVoteForId] || [];

        setDesignVotes({
          ...designVotes,
          [designToVoteForId]: [...votesForDesign, newDesignVote],
        });

        setVotedForDesign(designToVoteForId);

        setTimeout(() => {
          setVotedForDesign(null);
        }, 1000);
      })
      .catch((e) => {
        const hasVoted = e.response?.status === 409;

        toast({
          position: 'top',
          title: hasVoted ? 'You already voted for this design' : 'Error voting for design',
          status: 'error',
          duration: 1000,
        });
      })
      .finally(() => {
        setVotingForDesign(null);
      });
  };

  if (showRemixDetails && isMobile) {
    return (
      <RemixDetails
        designId={design.id}
        designVotes={designVotes}
        isVoting={false}
        onRemix={() => handleRemix()}
        onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
      />
    );
  }

  if (isError) {
    toast({
      title: 'Error loading collection',
      status: 'error',
      duration: 2000,
    });
  }

  return (
    <Box bg="#FFFFFF" h="100%" overflow="auto" pb="80px">
      {isLoading || isWaiting ? (
        <Center position="absolute" w="100%" h="100%" bg={'transparent'} opacity="0.5" top={0}>
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
        </Center>
      ) : (
        template && (
          <>
            {onBack ? (
              <Hide above="md">
                <Box p="12px 20px 12px 0">
                  <Button onClick={onBack} variant="ghost">
                    <IconBack />
                  </Button>
                </Box>
              </Hide>
            ) : null}
            <Box position="relative">
              <Image
                objectFit="cover"
                src={previewImages[0]?.url}
                alt="Collection image"
                h={{ base: IMAGE_MOBILE_SIZE, md: IMAGE_DESKTOP_SIZE }}
                w={{ base: IMAGE_MOBILE_SIZE, md: '100%' }}
              />
              <Box
                bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 7.5%, rgba(0, 0, 0, 0.5) 75%)"
                position="absolute"
                h="100%"
                w="100%"
                left={0}
                top={0}
              >
                <Box
                  p={{ base: '30px 25px 33px 25px', md: '90px 25px 33px 60px' }}
                  position="absolute"
                  left={0}
                  bottom={0}
                >
                  <Heading color="#FFFFFF" fontSize={{ base: '32px', md: '46px' }}>
                    {name}
                  </Heading>
                  <Text
                    color="#FFFFFF"
                    fontSize={{ base: '16px', md: '16px' }}
                    fontWeight={500}
                    mt="26px"
                    w={{ base: 'auto', md: '566px' }}
                  >
                    {(collectionDescription || '').slice(0, 200)}
                  </Text>
                  <HStack mt="33px" spacing="38px">
                    {getStats(template).map(({ name, value }) => (
                      <Box key={name}>
                        <Text color="#FFFFFF" fontSize="md" fontWeight={500}>
                          {name}
                        </Text>
                        <Text color="#FFFFFF" fontSize="24px" fontWeight={700}>
                          {value ? value.toLocaleString() : 0}
                        </Text>
                      </Box>
                    ))}
                  </HStack>
                </Box>
              </Box>
            </Box>
            <VStack p={{ base: '24px 17px', md: '60px 50px 100px 50px' }}>
              <Text
                mb="24px"
                alignSelf={{ base: votingEnded ? 'flex-start' : 'center', md: 'flex-start' }}
                textAlign="center"
                fontSize="20px"
                fontWeight={700}
              >
                {votingEnded ? 'Meet The Winners!' : 'Vote to win this merch for free!'}
              </Text>
              <RemixesList
                votingForDesign={votingForDesign}
                votingEnded={votingEnded}
                onVote={handleVote}
                onSelectedDesign={handleDesignSelected}
                template={template}
                designVotes={designVotes}
                votedForDesign={votedForDesign}
              />
            </VStack>
            {showRemixDetails && (
              <RemixDetailsModal
                designId={design.id}
                designVotes={designVotes}
                isVoting={votingForDesign === design.id}
                onClose={() => setShowRemixDetails(false)}
                onRemix={() => handleRemix()}
                onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
                votedForDesign={votedForDesign}
              />
            )}
          </>
        )
      )}
    </Box>
  );
}
