import { Box, ButtonProps, HStack, Hide, Show, Spinner, Text, VStack } from '@chakra-ui/react';

import { useEffect, useRef, useState } from 'react';

import {
  AbloImage,
  Style,
  StyleType,
  TextToImageRequest,
  TextToImageResponse,
} from '../../../types';

import StyleSelector from '../components/style-selector';
import IconError from '../components/IconError';
import ImagesPreview from '../components/ImagesPreview';
import IpInfringementAlert from '../components/IpInfringementAlert';
import { Favorite } from '@/lib';
import { DEFAULT_REFERENCE_IMAGE_STRENGTH } from '../../../../constants';

import { AbloSlider } from '../../components/AbloSlider';
import getCroppedImg, { DEFAULT_CROP, DEFAULT_ZOOM } from '../image-to-image/cropImage';
import { CropTool } from '../../components/CropTool';
import PromptInput from '../components/PromptInput';
import Button from '../../../components/Button';
import { IconAi } from '../../../components/icons/IconAi';
import CreditCostIcon from '../components/CreditCostIcon';

const defaultParams = {
  styleId: '',
  freeText: '',
};

const SAMPLES = 3;

type TextToImageGeneratorProps = {
  actionCost?: number;
  onImagesPreview: (images: AbloImage[]) => void;
  onGeneratedImageSelected: (image: AbloImage) => void;
  getStyles: (type: StyleType) => Promise<Style[]>;
  generateImageFromText: (options: TextToImageRequest) => Promise<TextToImageResponse>;
  hideStyles: boolean;
  buttonProps?: {
    basic: ButtonProps;
    outlined: ButtonProps;
  };
  subjectText: string;
  addFavorite: (favorite: Favorite) => void;
  favorites: Favorite[];
  removeFavorite: (id: string) => void;
};

export default function TextToImageGenerator({
  actionCost,
  onGeneratedImageSelected,
  onImagesPreview,
  getStyles,
  generateImageFromText,
  hideStyles,
  buttonProps,
  addFavorite,
  favorites,
  removeFavorite,
}: TextToImageGeneratorProps) {
  const subjectInputRef = useRef(null);

  const [styles, setStyles] = useState<Style[]>([]);
  const [isGenerating, setWaiting] = useState(false);
  const [error, setError] = useState<string>(null);
  const [options, setOptions] = useState<TextToImageRequest>(defaultParams);
  const [selectedImage, setSelectedImage] = useState<AbloImage>(null);
  const [images, setImages] = useState([]);
  const [riskScore, setRiskScore] = useState(0);
  const [referenceImageFile, setReferenceImageFile] = useState<{ file: File; objectUrl?: string }>(
    null
  );
  const [referenceImageStrength, setReferenceImageStrength] = useState(
    DEFAULT_REFERENCE_IMAGE_STRENGTH
  );
  const [isCropDone, setIsCropDone] = useState(false);
  const [crop, setCrop] = useState(DEFAULT_CROP);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [shouldBypassCache, setShouldBypassCache] = useState(false);

  const { styleId, freeText } = options;

  const style = styles.find(({ id }) => id === styleId);

  useEffect(() => {
    getStyles('text').then((styles) => {
      setStyles(styles);

      if (styles?.length === 1) {
        setOptions((options) => ({ ...options, styleId: styles[0].id }));
      }
    });
  }, [getStyles]);

  useEffect(() => {
    if (subjectInputRef.current) {
      subjectInputRef.current.focus();
    }
  }, []);

  const handlePlaceArtwork = () => {
    onGeneratedImageSelected({
      id: selectedImage.id,
      options: { ...options, style: style.name },
      url: selectedImage.url,
    });
  };

  const handleReset = () => {
    setImages([]);
    setSelectedImage(null);
    setError(null);

    if (onImagesPreview) {
      onImagesPreview(null);
    }

    const options = { ...defaultParams };

    if (styles?.length === 1) {
      options.styleId = styles[0].id;
    }
    setOptions(options);
  };

  const handleUpdate = (updates) => {
    setOptions({ ...options, ...updates });
    setShouldBypassCache(false);
  };

  const handleGenerate = async () => {
    setError(null);
    setWaiting(true);
    setImages([]);
    setRiskScore(0);

    const requestParams: TextToImageRequest = {
      styleId,
      freeText,
      shouldBypassCache,
      referenceImageFile: referenceImageFile?.file,
      ipAdapterScale: referenceImageStrength / 100,
      samples: SAMPLES,
    };

    generateImageFromText(requestParams)
      .then((res) => {
        setWaiting(false);

        setImages(res.images);
        setRiskScore(res.riskScore);
        setSelectedImage(res.images[0]);

        if (onImagesPreview) onImagesPreview(res.images);
        setShouldBypassCache(true);
      })
      .catch((err) => {
        setWaiting(false);

        const message = err?.response?.data?.message;

        if (message) {
          if (message.includes('Bad words') || message.includes('Invalid prompts')) {
            setError('Inappropriate prompt. Please type again');
          } else {
            setError(message);
          }
        }
      });
  };

  const onSetCrop = async () => {
    const { file: croppedImage } = await getCroppedImg(
      referenceImageFile.objectUrl,
      croppedAreaPixels
    );

    setReferenceImageFile({
      file: new File([croppedImage], referenceImageFile.file.name, { type: 'image/png' }),
    });

    setCroppedAreaPixels(null);
    setIsCropDone(true);
    setCrop(DEFAULT_CROP);
    setZoom(DEFAULT_ZOOM);
  };

  const onCropComplete = (_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleReferenceImageFileChange = (file: File): void => {
    setReferenceImageFile(file ? { file, objectUrl: URL.createObjectURL(file) } : null);
    setCroppedAreaPixels(null);
    setIsCropDone(false);
    setCrop(DEFAULT_CROP);
    setZoom(DEFAULT_ZOOM);
  };

  const renderStyles = () => (
    <StyleSelector
      styles={styles}
      selectedStyleId={styleId}
      onSelectedStyle={(styleId) => {
        handleUpdate({ styleId });
      }}
      referenceImageFile={referenceImageFile?.file}
      onReferenceImageFileChange={handleReferenceImageFileChange}
    />
  );

  return (
    <Box
      p="0 14px 26px 14px"
      onKeyPress={async (e) => {
        if (e.key === 'Enter') {
          await handleGenerate();
        }
      }}
    >
      {images.length > 0 && (
        <VStack align="flex-start" w="100%">
          <IpInfringementAlert riskScore={riskScore}></IpInfringementAlert>
          <ImagesPreview
            images={images}
            selectedImage={selectedImage}
            onSelectedImage={setSelectedImage}
            onPlaceArtwork={handlePlaceArtwork}
            onGenerateSimilar={() => handleGenerate()}
            onNewArtwork={handleReset}
            buttonProps={buttonProps}
            showGenerateNewButtons={false}
            addFavorite={addFavorite}
            favorites={favorites}
            removeFavorite={removeFavorite}
          />
          <Box bg="#E0E5F2" width="90%" height="1px" />
        </VStack>
      )}
      <PromptInput
        onChange={(e) => handleUpdate({ freeText: e.target.value })}
        ref={subjectInputRef}
        value={freeText}
        placeholder="Enter your prompt"
      />
      <Hide above="3sm">{!hideStyles ? renderStyles() : null}</Hide>
      {referenceImageFile && !isCropDone && (
        <CropTool
          crop={crop}
          imageFile={referenceImageFile.objectUrl}
          onCropComplete={onCropComplete}
          onCropChange={setCrop}
          zoom={zoom}
          onZoomChange={setZoom}
          onSetCrop={onSetCrop}
        />
      )}
      {referenceImageFile && (
        <AbloSlider
          defaultValue={referenceImageStrength}
          min={1}
          max={100}
          step={1}
          height="2px"
          onChange={setReferenceImageStrength}
          value={referenceImageStrength}
          width="100%"
          label="Reference Image Strength"
        />
      )}
      <Show above="3sm">{!hideStyles ? renderStyles() : null}</Show>
      {error ? (
        <Box padding="8px 14px">
          <HStack w="100%" borderRadius="9px" p="10px 16px 10px 16px" bg="#FBEDEC" spacing="5px">
            <IconError />
            <Text color="black.600" fontSize="sm">
              {error}
            </Text>
          </HStack>
        </Box>
      ) : null}
      {(!referenceImageFile || (referenceImageFile && isCropDone)) && (
        <Button
          isDisabled={!freeText || !styleId || isGenerating}
          onClick={() => handleGenerate()}
          title={shouldBypassCache ? 'Re-Generate' : 'Generate'}
          w="100%"
          {...(buttonProps?.basic || {})}
          icon={isGenerating ? <Spinner /> : <IconAi />}
          iconRight={
            !freeText || !styleId || isGenerating ? <CreditCostIcon cost={actionCost} /> : null
          }
        />
      )}
    </Box>
  );
}
