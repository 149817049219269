import { Box, Heading, Hide, Image, Show, Stack, Text, VStack } from '@chakra-ui/react';

import ImageMerch from './images/voting.gif';

const VoteToWinMerch = () => (
  <Box mt={{ base: 0, lg: '60px' }}>
    <Stack direction={{ base: 'column', md: 'row' }} align="center" justify="center" spacing={0}>
      <VStack
        align="flex-start"
        mr={{ base: 0, md: '32px', lg: '125px' }}
        w={{ base: '100%', md: '360px', lg: '407px' }}
      >
        <Heading
          alignSelf={{ base: 'center', md: 'flex-start' }}
          fontSize={{ base: '22px', md: '28px', lg: '34px' }}
          mb={{ base: 0, md: '12px' }}
          w={{ base: '330px', md: '360px', lg: '377px' }}
          textAlign={{ base: 'center', md: 'left' }}
        >
          Vote for a chance to win merch
        </Heading>
        <Hide above="2sm">
          <Image src={ImageMerch} mb="24px" mt="12px" w="100%" />
        </Hide>
        <Box padding={{ base: '0 16px', md: 0 }}>
          <Text fontSize="md" fontWeight={700} mb="4px">
            Support your favorite designs and win big!
          </Text>
          <Text fontSize="md" opacity={0.6}>
            Cast your vote on the latest creations, whether they’re from fellow designers or
            renowned artists. Each vote you cast gives you a chance to win exclusive garments. Your
            voice matters—help decide which designs become the next must-have items!
          </Text>
        </Box>
      </VStack>
      <Show above="md">
        <Image borderRadius="24px" src={ImageMerch} mt="12px" w={{ '2sm': 379, lg: 462 }} h={280} />
      </Show>
    </Stack>
  </Box>
);

export default VoteToWinMerch;
