import { Fragment as F } from 'react';
import { HStack, Hide, Stack, VStack, useBreakpointValue } from '@chakra-ui/react';
import FeatureCard from '../components/FeatureCard';

import BannerForArtists from './banners-for-user-types/banner_for_artists.png';
import BannerForBrands from './banners-for-user-types/banner_for_brands.png';
import BannerForCollectors from './banners-for-user-types/banner_for_collectors.png';
import Button from '@/components/button';
import FeatureCardEmpty from '../components/FeatureCardEmpty';
import { chunk } from 'lodash';

const FEATURES = [
  {
    entity: 'Creators',
    image: BannerForArtists,
    features: [
      {
        title: 'Collaborate with top brands',
        subtitle: 'Join forces with big names',
      },
      {
        title: 'Submit your art',
        subtitle: 'Link your e-wallet and upload',
      },
      {
        title: 'Gather votes',
        subtitle: 'Share and boost your designs',
      },
      {
        title: 'Sell your merch',
        subtitle: 'Turn your art into products',
      },
    ],
  },
  {
    entity: 'Brands',
    image: BannerForBrands,
    features: [
      {
        title: 'Partner with creators',
        subtitle: 'Co-create with top talent',
      },
      {
        title: 'Streamline submissions',
        subtitle: 'Easy art uploads with e-wallets',
      },
      {
        title: 'Engage you audience',
        subtitle: 'Drive votes on designs',
      },
      {
        title: 'Produce winning merch',
        subtitle: 'Bring top designs to life',
      },
    ],
  },
  {
    entity: 'Collectors',
    image: BannerForCollectors,
    features: [
      {
        title: 'Invest in IPs',
        subtitle: 'Own exclusive digital art',
      },
      {
        title: 'Secure your assets',
        subtitle: 'Link your e-wallet and buy',
      },
      {
        title: 'Earn royalties',
        subtitle: 'Profit from your investments',
      },
      {
        title: 'Influence the outcome',
        subtitle: 'Vote on what gets made',
      },
    ],
  },
];

interface FeaturesForUserTypesProps {
  onRemix: () => void;
  waiting: boolean;
}

const FeaturesForUserTypes = ({ waiting, onRemix }: FeaturesForUserTypesProps) => {
  const isTablet = useBreakpointValue({ base: false, '2sm': true, lg: false });

  if (isTablet) {
    const chunks = chunk([...FEATURES, { entity: null, image: null, features: [] }], 2);

    return (
      <VStack spacing="48px">
        {chunks.map((chunk) => (
          <HStack align="flex-start" justify="center" spacing="12px" w="100%">
            {chunk.map((chunk) => {
              if (!chunk.entity) {
                return <FeatureCardEmpty onRemix={onRemix} waiting={waiting} />;
              }

              const { entity, image, features } = chunk;

              return <FeatureCard entity={entity} image={image} key={entity} features={features} />;
            })}
          </HStack>
        ))}
      </VStack>
    );
  }

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} justify="center" spacing="12px" w="100%">
      {FEATURES.map(({ entity, image, features }) => (
        <F key={entity}>
          <FeatureCard entity={entity} image={image} key={entity} features={features} />
          <Hide above="2sm">
            <Button
              alignSelf="center"
              isLoading={waiting}
              h="50px"
              m="12px 0 28px 0"
              onClick={onRemix}
              w="calc(100% - 32px)"
            >
              Start designing
            </Button>
          </Hide>
        </F>
      ))}
    </Stack>
  );
};

export default FeaturesForUserTypes;
