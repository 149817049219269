import React from 'react';
import ReactDOM from 'react-dom/client';

import { isAxiosError } from 'axios';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { IntercomProvider } from 'react-use-intercom';

import * as Sentry from '@sentry/react';

import AdminDashboard from '@/layouts/admin';
import Auth from '@/layouts/auth';
import { ChakraProvider } from '@chakra-ui/react';
import ResetPasswordPage from '@/views/auth/reset-password';
import theme from '@/theme';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Config from './config';
import { PageTracker } from './analytics/PageTracker';

import './index.css';
import { Helmet } from 'react-helmet';

const { ENVIRONMENT, GOOGLE_CLIENT_ID, INTERCOM_APP_ID, SENTRY_DSN } = Config;

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const MAX_RETRIES = 6;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404, 409];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }

        if (isAxiosError(error) && HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)) {
          console.log(`Aborting retry due to ${error.response?.status} status`);
          return false;
        }

        return true;
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <BrowserRouter>
              <PageTracker />
              <Helmet>
                {ENVIRONMENT !== 'production' ? <meta content="noindex" name="robots" /> : null}
              </Helmet>
              <Switch>
                <Route path={`/auth`} component={Auth} />
                <Route path={`/reset-password`} component={ResetPasswordPage} />
                <Route path={`/`} component={AdminDashboard} />
              </Switch>
            </BrowserRouter>
          </ChakraProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </IntercomProvider>
  </React.StrictMode>
);
