import axios from 'axios';

import { TextToImageRequest, TextToImageResponse } from '@/lib/types';

export const generateImageFromText = (params: TextToImageRequest): Promise<TextToImageResponse> => {
  return axios
    .post('/image-maker', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      return data;
    });
};
