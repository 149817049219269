import { useEffect, useState } from 'react';

import { Box, useBreakpointValue } from '@chakra-ui/react';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useIntercom } from 'react-use-intercom';
import { usePubNub } from 'pubnub-react';

import { globalStyles } from '@/theme/styles';
import OutOfCreditsModal from '@/components/subscription/OutOfCreditsModal';
import { User } from '@/components/types';

import ROUTES from './routes';

import DesignPreviewPage from '@/views/demo/pages/design/DesignPreviewPage';
import HomeGuest from '@/views/home/HomeGuest';
import { useQueryClient } from '@tanstack/react-query';
import CollectionGuest from '../../views/collection/CollectionGuest';

const {
  colors: { brand },
} = globalStyles;

const LOW_CREDITS_THRESHOLD = 10;

export default function Admin({ me }) {
  const [isOutOfCreditsModalVisible, setOutOfCreditsModalVisible] = useState(false);

  const location = useLocation();

  const { boot, update } = useIntercom();

  const queryClient = useQueryClient();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const pubnub = usePubNub();

  useEffect(() => {
    if (!me) {
      return;
    }

    const handleMessage = (event) => {
      const message = event.message;

      const { creditsRemaining } = message;

      if (creditsRemaining < LOW_CREDITS_THRESHOLD) {
        setOutOfCreditsModalVisible(true);
      }

      queryClient.setQueryData(['me'], (oldMe: User) => ({
        ...oldMe,
        client: {
          ...oldMe.client,
          credits: creditsRemaining,
        },
      }));
    };

    const listenerParams = { message: handleMessage };

    const channels = [`client_${me.client.id}`];

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels });

    return () => {
      pubnub.unsubscribe({ channels });
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, me, queryClient]);

  useEffect(() => {
    if (!me) {
      return;
    }

    const pathname = location.pathname;

    boot({
      actionColor: brand[500],
      backgroundColor: brand[600],
      email: me.email,
      userId: me.id,
    });

    update({ hideDefaultLauncher: isMobile && pathname.includes('/designs') });
  }, [me, boot, location.pathname, isMobile, update]);

  if (!me) {
    return (
      <Switch>
        <Route path="/designs/:id" component={DesignPreviewPage} />
        <Route exact path="/" component={HomeGuest} />
        <Route path="/collection/:id" component={CollectionGuest} />
      </Switch>
    );
  }

  const allRoutes = ROUTES.reduce((result, route) => {
    if (route.routes) {
      return [...result, ...route.routes];
    }

    return [...result, route];
  }, []);

  return (
    <Box h="100%">
      <Switch>
        {allRoutes.map((route, key) => {
          const { exact, path, component } = route;

          return <Route path={path} exact={exact} component={component} key={key} />;
        })}
        <Redirect to="/" />
      </Switch>
      {isOutOfCreditsModalVisible ? (
        <OutOfCreditsModal onClose={() => setOutOfCreditsModalVisible(false)} />
      ) : null}
    </Box>
  );
}
