import {
  Box,
  Card,
  CardBody,
  Center,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';

import { Design } from '@/lib/types';

import { useEffect, useState } from 'react';
import { getDesignPreviewImage } from '@/lib';
import IconVote from './icons/IconVote';

import CreatorAvatar from '@/views/home/components/CreatorAvatar';
import VoteButton from './VoteButton';

const THUMBNAIL_MOBILE_SIZE = 174;
const THUMBNAIL_DESKTOP_SIZE = 224;

type RemixCardProps = {
  design: Design;
  votingForDesign: string;
  onDesignSelected: (design: Design) => void;
  onVote: () => void;
};

const RemixCard = ({ design, votingForDesign, onDesignSelected, onVote }: RemixCardProps) => {
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  useEffect(() => {
    getDesignPreviewImage(design)
      .then(({ previewImage }) => {
        setDesignPreviewImage(previewImage);

        setIsLoadingPreviewImage(false);
      })
      .catch((e) => console.log('E', e));
  }, []);

  const { id, name, numVotes, user } = design;

  const isVotingDisabled = !onVote;

  return (
    <Card
      h={{ base: '287px', lg: '370px' }}
      w="100%"
      boxShadow="0px 2.33px 8.73px 0px rgba(0, 52, 139, 0.15)"
      borderRadius={12}
      key={id}
      position="relative"
    >
      <CardBody p={0}>
        <Center
          cursor="pointer"
          h={{ base: `${THUMBNAIL_MOBILE_SIZE}px`, lg: `${THUMBNAIL_DESKTOP_SIZE}px` }}
          onClick={() => onDesignSelected(design)}
          position="relative"
        >
          {isLoadingPreviewImage || !designPreviewImage ? null : (
            <Image
              borderRadius={12}
              h="100%"
              w="100%"
              objectFit="contain"
              src={designPreviewImage}
            />
          )}
          {isLoadingPreviewImage ? (
            <Center
              position="absolute"
              w="100%"
              h="100%"
              bg={isLoadingPreviewImage ? 'transparent' : 'black'}
              opacity="0.5"
              top={0}
            >
              <Spinner
                thickness="1px"
                speed="0.65s"
                emptyColor="gray"
                color="brand.500"
                size="md"
              />
            </Center>
          ) : null}
        </Center>
        <VStack align="left" p="6px 10px" spacing="8px">
          <Box>
            <Text
              fontSize="md"
              fontWeight={700}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {name}
            </Text>
            <CreatorAvatar user={user} />
          </Box>
          <VoteButton
            isDisabled={isVotingDisabled}
            isLoading={votingForDesign === design.id}
            h="40px"
            mt={{ base: 0, lg: '20px' }}
            onClick={onVote}
            p="0 16px"
          >
            <HStack spacing={1}>
              {isVotingDisabled ? null : <IconVote />}
              <Text>
                {numVotes} Vote{numVotes === 1 ? '' : 's'}
              </Text>
            </HStack>
          </VoteButton>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default RemixCard;
