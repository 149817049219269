import { Flex, Heading, Hide, Show, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import ButtonCTA from '../components/ButtonCTA';
import CreateYourStyleVideo from '../components/CreateYourStyleVideo';

interface CraftDesignsSectionProps {
  onAction: () => void;
}

const CraftDesignsSection = ({ onAction }: CraftDesignsSectionProps) => {
  const isMobile = useBreakpointValue({ base: true, '2sm': false });

  return (
    <Flex
      bg="#282C36"
      borderRadius={{ base: 0, '2sm': '24px' }}
      justify="space-between"
      p={{ base: '26px 15px 39px 15px', '2sm': '51px 65px', lg: '64px 78px' }}
      w="100%"
    >
      <VStack
        alignItems={{ base: 'flex-start', '2sm': 'center', lg: 'flex-start' }}
        justify={{ base: 'center', lg: 'space-between' }}
        w={{ base: '100%', lg: '395px' }}
      >
        <Heading color="#FFFFFF" fontSize="30px" mb="18px">
          Craft unique designs
        </Heading>
        <Hide above="lg">
          <CreateYourStyleVideo width={isMobile ? 'auto' : '640px'} />
        </Hide>
        <Text
          color="#FFFFFF"
          fontSize={{ base: '16px', '2sm': '24px', lg: '16px' }}
          fontWeight={700}
          mb="4px"
          mt={{ base: '32px', lg: 0 }}
        >
          Unlock your creativity with Ablo!
        </Text>
        <Text
          color="#FFFFFF"
          fontWeight={500}
          maxW={{ base: 'auto', '2sm': '507px', lg: 'auto' }}
          opacity="50%"
          textAlign={{ base: 'left', '2sm': 'center', lg: 'left' }}
        >
          Remix a collection with top brands or talented creators, or start from scratch and craft
          your own unique designs. Whether you’re blending iconic styles or inventing something
          entirely new, Ablo gives you the tools to bring your vision to life.{' '}
        </Text>
        <Text color="#FFFFFF" fontWeight={700} mb="40px" mt="32px">
          Your style, your rules!
        </Text>
        <ButtonCTA onClick={onAction} w={{ base: '100%', '2sm': '379px', lg: '204px' }}>
          Start designing
        </ButtonCTA>
      </VStack>
      <Show above="lg">
        <CreateYourStyleVideo width="461px" />
      </Show>
    </Flex>
  );
};

export default CraftDesignsSection;
