import { Box, Center, HStack, Heading } from '@chakra-ui/react';
import IconBalmain from './brand-icons/Balmain';
import IconPangaia from './brand-icons/Pangaia';
import IconSmiley from './brand-icons/Smiley';
import IconAmazon from './brand-icons/Amazon';
import IconGraz from './brand-icons/Graz';
import IconLiveNation from './brand-icons/LiveNation';
import IconKickz from './brand-icons/Kickz';
import IconMidnightStudios from './brand-icons/MidnightStudios';
import IconLaQuan from './brand-icons/LaQuan';
import IconHojeon from './brand-icons/Hojeon';

const BRANDS_ICONS = [
  <IconBalmain />,
  <IconPangaia />,
  <IconSmiley />,
  <IconAmazon />,
  <IconGraz />,
  <IconLiveNation />,
  <IconKickz />,
  <IconMidnightStudios />,
  <IconLaQuan />,
  <IconHojeon />,
];

const Brands = () => (
  <>
    <Heading
      fontSize={{ base: '24px', md: '30px' }}
      mb={{ base: '24px', md: '32px' }}
      textAlign="center"
    >
      Brands we worked with
    </Heading>
    <Center>
      <HStack
        mb={{ base: '40px', md: '60px' }}
        p="0 16px"
        spacing={{ base: '20px', md: '30px' }}
        wrap="wrap"
        justifyContent="space-evenly"
      >
        {BRANDS_ICONS.map((icon, index) => (
          <Box key={index}>{icon}</Box>
        ))}
      </HStack>
    </Center>
  </>
);

export default Brands;
