import { useEffect, useState } from 'react';

import {
  Box,
  Center,
  Flex,
  Heading,
  Hide,
  Show,
  Spinner,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { getTemplates } from '@/api/templates';
import { DesignVote, Template } from '@/components/types';

import { Design, getTemplateWithPreviewImage } from '@/lib';
import Button from './components/ButtonCTA';

import RemixesList from '@/components/remix/RemixesList';

import RemixDetails from './RemixDetails';
import RemixDetailsModal from './RemixDetailsModal';

import { useHistory, useLocation } from 'react-router-dom';
import { getDesignVotes, saveUserDesign, voteForDesign } from '@/api/designs';
import { groupBy, isEmpty } from 'lodash';
import FeaturesForUserTypes from './fragments/FeaturesForUserTypes';
import Brands from './fragments/Brands';
import CraftDesignsSection from './fragments/CraftDesignsSection';
import TemplatePreviewImage from './components/TemplatePreviewImage';
import Countdown from './components/Countdown';

import IconMagic from '@/components/icons/IconMagic';
import RemixesSubmitted from './components/RemixesSubmitted';
import VoteToWinMerch from './fragments/VoteToWinMerch';
import KBWVideo from './components/KBWVideo';

const getVotesForDesigns = (templateId: string, designVotes: DesignVote[]) => {
  const votesForTemplate = designVotes.filter((designVote) => designVote.templateId === templateId);

  return groupBy(votesForTemplate, 'designId');
};

interface HomeProps {
  onSignInToVote?: (designId: string) => void;
  onSignInToRemix?: () => void;
}

export default function Home({ onSignInToVote, onSignInToRemix }: HomeProps) {
  const [template, setTemplate] = useState<Template>(null);
  const [isLoading, setLoading] = useState(true);

  const [secondsLeftToContestEnd, setSecondsLeftToContestEnd] = useState(null);

  const [designId, setDesignId] = useState(null);

  const [designVotes, setDesignVotes] = useState({});
  const [votingForDesign, setVotingForDesign] = useState<string>(null);
  const [votedForDesign, setVotedForDesign] = useState<string>(null);

  const [waiting, setWaiting] = useState(false);

  const history = useHistory();

  const toast = useToast();

  const { search } = useLocation();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleVote = (designToVoteForId: string) => {
    if (onSignInToVote) {
      onSignInToVote(designToVoteForId);

      return;
    }

    setVotingForDesign(designToVoteForId);

    voteForDesign(designToVoteForId)
      .then((newDesignVote) => {
        const votesForDesign = designVotes[designToVoteForId] || [];

        setDesignVotes({
          ...designVotes,
          [designToVoteForId]: [...votesForDesign, newDesignVote],
        });

        setVotedForDesign(designToVoteForId);

        setTimeout(() => {
          setVotedForDesign(null);
        }, 1000);

        if (!designId) {
          handleSelectDesign({ id: designToVoteForId });
        }
      })
      .catch((e) => {
        const hasVoted = e.response?.status === 409;

        toast({
          position: 'top',
          title: hasVoted ? 'You already voted for this design' : 'Error voting for design',
          status: 'error',
          duration: 1000,
        });
      })
      .finally(() => {
        setVotingForDesign(null);
        setLoading(false);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    setDesignId(queryParams.get('designId'));
  }, [search]);

  useEffect(() => {
    let intervalId;

    const queryParams = new URLSearchParams(search);

    const votingForDesign = queryParams.get('votingForDesign');
    const remix = queryParams.get('remix');

    Promise.all([getTemplates({ isFeatured: true }), getDesignVotes().catch(() => [])])
      .then(([templates, designVotes]) => {
        return getTemplateWithPreviewImage(templates[0]).then((featuredTemplate) => {
          setTemplate(featuredTemplate);

          setDesignVotes(getVotesForDesigns(featuredTemplate.id, designVotes));

          const { contestEndAt } = featuredTemplate;

          const getSeconds = () =>
            Math.floor((new Date(contestEndAt).getTime() - new Date().getTime()) / 1000);

          const secondsToEnd = getSeconds();

          if (!contestEndAt || secondsToEnd <= 0) {
            setSecondsLeftToContestEnd(0);

            return;
          }

          setSecondsLeftToContestEnd(secondsToEnd);

          intervalId = setInterval(() => {
            const seconds = getSeconds();

            setSecondsLeftToContestEnd(Math.max(seconds, 0));

            if (seconds < 0) {
              clearInterval(intervalId);
            }
          }, 1000);

          if (votingForDesign) {
            handleVote(votingForDesign);
          } else if (remix) {
            handleRemix(featuredTemplate);
          }
        });
      })
      .finally(() => {
        if (!votingForDesign) {
          setLoading(false);
        }
      });

    return () => clearInterval(intervalId);
  }, []);

  const handleSelectDesign = (design: Design | { id: string }) => {
    history.replace(`?designId=${design.id}`);
  };

  const handleCloseSubpage = () => {
    history.replace('/');
  };

  const handleGoToCollection = () => {
    history.push(`collection/${template.id}`);
  };

  const handleRemix = async (featuredTemplate?) => {
    if (onSignInToRemix) {
      onSignInToRemix();

      return;
    }

    const templateToUse = featuredTemplate || template;

    const { colors, id } = templateToUse;

    const sides = templateToUse.sides.map((side) => ({
      templateSideId: side.id,
    }));

    const params = {
      sides,
      template,
      templateId: id,
      templateColorId: colors[0].id,
    };

    setWaiting(true);

    try {
      const newDesign = await saveUserDesign(params as Design);

      history.push(`/designs/${newDesign.id}`);
    } catch (e) {
      toast({
        title: 'Error creating a remix',
        status: 'error',
        duration: 2000,
      });
    } finally {
      setWaiting(false);
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Center bg="transparent" h="calc(100vh - 100px)">
        <Spinner thickness="1px" speed="0.65s" emptyColor="gray" color="brand.500" size="md" />
      </Center>
    );
  }

  const votingEnded = secondsLeftToContestEnd === 0;

  if (designId && isMobile) {
    return (
      <RemixDetails
        isVoting={votingForDesign === designId}
        designVotes={designVotes}
        designId={designId}
        onBack={handleCloseSubpage}
        onRemix={() => handleRemix()}
        onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
      />
    );
  }

  return (
    <Center bg="#FFFFFF" h="100%" w="100%">
      <Box
        h="100%"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none', // IE and Edge
          'scrollbar-width': 'none', // Firefox
        }}
        pb={{ base: '80px', lg: 0 }}
        pt={{ base: '36px', '2sm': '40px', lg: '90px' }}
        px={{ base: 0, '2sm': '32px' }}
        w={{ base: '100%', xl: '1170px' }}
      >
        <Stack
          align={{ base: 'center', '2sm': 'flex-start' }}
          justify={{ base: 'center', lg: 'space-between' }}
          direction={{ base: 'column', lg: 'row' }}
          mb={{ base: '50px', '2sm': '100px', lg: '141px' }}
          px={{ base: '16px', '2sm': 0 }}
          spacing={{ base: '8px', xl: '24px' }}
          w="100%"
        >
          <Box flex={1}>
            <Heading fontSize="46px" maxW="304px" mb={{ base: '32px', lg: 0 }}>
              Create. Collaborate. Earn.
            </Heading>
            <Hide above="xl">
              <KBWVideo width="100%" />
            </Hide>
            <Text
              fontWeight={500}
              fontSize={{ base: '16px', '2sm': '22px' }}
              mt={{ base: '32px', '2sm': '18px' }}
              mb={{ base: '26px', '2sm': '32px' }}
              w={{ base: '340px', '2sm': '100%' }}
            >
              Design with AI, partner with leading brands, and monetize your creativity on Web3.
            </Text>
            <Stack direction={{ base: 'column', '2sm': 'row' }} w={{ base: '100%', lg: '416px' }}>
              <Button
                isLoading={waiting}
                flex={{ base: 'auto', md: 1 }}
                onClick={() => handleRemix()}
              >
                Start designing
              </Button>
              <Button
                flex={{ base: 'auto', md: 1 }}
                onClick={() => handleGoToCollection()}
                secondary
              >
                Vote to win
              </Button>
            </Stack>
          </Box>
          <Show above="xl">
            <KBWVideo height="391px" />
          </Show>
          <Hide above="md">
            <Box />
          </Hide>
        </Stack>
        <FeaturesForUserTypes onRemix={() => handleRemix()} waiting={waiting} />
        <Show above="lg">
          <Flex justify="center" p="45px 0 60px 0">
            <Button isLoading={waiting} onClick={() => handleRemix()} w="224px">
              Start designing
            </Button>
          </Flex>
        </Show>
        <Box mb={{ base: '12px ' }} />
        <Brands />
        <CraftDesignsSection onAction={() => handleRemix()} />
        <Hide below="2sm" above="lg">
          <Heading
            alignSelf="center"
            textAlign="center"
            fontSize="34px"
            m="50px auto 32px auto"
            w="501px"
          >
            Remix the latest collection: {template.brand}
          </Heading>
        </Hide>
        <Stack
          direction={{ base: 'column', '2sm': 'row' }}
          justify="center"
          mt={{ base: '32px', md: '50px' }}
          spacing={{ base: 0, '2sm': '32px', lg: '50px' }}
        >
          <Show above="2sm">
            <TemplatePreviewImage template={template} />
          </Show>
          <VStack
            align={{ base: 'center', '2sm': 'flex-start' }}
            mb={{ base: '40px', '2sm': '60px', lg: '16px' }}
            p={{ base: 0, lg: '17px' }}
            maxW={{ base: 'none', lg: '420px' }}
            spacing={{ base: '16px', lg: 0 }}
            w="100%"
          >
            <Show below="2sm" above="lg">
              <Heading
                textAlign={{ base: 'center', lg: 'left' }}
                fontSize={{ base: '30px', lg: '34px' }}
                mb={{ base: 0, lg: '38px' }}
                w={{ base: '341px', lg: 'auto' }}
              >
                Remix the latest collection: {template.brand}
              </Heading>
            </Show>
            <Hide above="2sm">
              <Countdown secondsLeft={secondsLeftToContestEnd} />
            </Hide>
            <Hide above="2sm">
              <TemplatePreviewImage template={template} />
            </Hide>
            <VStack
              align="flex-start"
              p={{ base: '12px 16px 0 12px', lg: 0 }}
              spacing="8px"
              w="100%"
            >
              <Text
                fontSize={{ base: 'md', '2sm': '20px', lg: '24px' }}
                fontWeight={700}
                textTransform="uppercase"
              >
                {template.name}
              </Text>
              <Text fontSize={{ base: '14px', '2sm': '16px' }} fontWeight={500} opacity={0.6}>
                {template.collectionDescription}
              </Text>
              <Hide above="lg">
                <RemixesSubmitted numRemixes={template.numRemixes} />
              </Hide>
              <Stack direction={{ base: 'column', lg: 'row' }} mt="8px" spacing="12px" w="100%">
                <Button
                  isLoading={waiting}
                  onClick={() => handleRemix()}
                  w={{ base: '100%', lg: '204px' }}
                >
                  <IconMagic mr="6px" />
                  Remix
                </Button>
                <Button secondary onClick={handleGoToCollection} w={{ base: '100%', lg: '204px' }}>
                  <Text>Explore Collection</Text>
                </Button>
              </Stack>
              <Show above="lg">
                <RemixesSubmitted numRemixes={template.numRemixes} />
                <Box mb="2px" />
              </Show>
              <Show above="2sm">
                <Countdown secondsLeft={secondsLeftToContestEnd} />
              </Show>
            </VStack>
          </VStack>
        </Stack>
        <VoteToWinMerch />
        <VStack
          m={{ base: '24px 0 40px 0', md: '60px 0' }}
          p={{ base: '0 17px', '2sm': '0 0 500px 0', lg: '0 0 100px 0' }}
        >
          <RemixesList
            designVotes={designVotes}
            maxItems={4}
            onSelectedDesign={handleSelectDesign}
            template={template}
            onVote={handleVote}
            votingEnded={votingEnded}
            votingForDesign={votingForDesign}
            votedForDesign={votedForDesign}
          />
          <Button
            mt={{ base: '20px', md: '33px' }}
            secondary
            onClick={handleGoToCollection}
            w={{ base: '100%', md: '204px' }}
          >
            <Text>Explore more</Text>
          </Button>
        </VStack>
        {designId ? (
          <RemixDetailsModal
            designId={designId}
            designVotes={designVotes}
            isVoting={votingForDesign === designId}
            onClose={handleCloseSubpage}
            onRemix={() => handleRemix()}
            onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
            votedForDesign={votedForDesign}
          />
        ) : null}
      </Box>
    </Center>
  );
}
